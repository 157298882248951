import { DateTime } from 'luxon'

/**
   * Formats date to "12 Nov. 2021" format
   *
   * @param {string} date Date string to format
   */
function formatDate (date) {
  return DateTime.fromISO(date).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })
}

export {
  formatDate,
}
